<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-default">
        <div class="card-header bg-frontera">
          <h3 class="card-title text-white">
            <i class="fa fa-truck text-white"></i> <b>Solicitar Vehiculos</b>
          </h3>
        </div>
        <div class="panel-body">
          <table class="table table-striped table-advance table-hover">
            <thead>
              <tr>
                <th style="width: 30%">OPERACION</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="tif_operacion_id in listasForms.operaciones"
                :key="tif_operacion_id.id"
              >
                <td class="text-center">
                  {{ tif_operacion_id["Noperacion"] }}
                  <button
                    type="button"
                    class="btn btn-primary"
                    style="float: right"
                    data-toggle="modal"
                    data-target="#modal_detalle"
                    @click="
                      getDatosModalAdd(tif_operacion_id['id']) &&
                        resetForm(tif_operacion_id['id'])
                    "
                    v-if="
                      $store.getters.can('tif.solicitudesVehiculos.edit') &&
                      $parent.permite_editar
                    "
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </td>
                <td>
                  <table
                    class="table table-hover table-bordered table-sm"
                    style="font-size: 15px; margin: 0"
                    v-if="validarNomi(tif_operacion_id['id'])"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center">Tipo de Vehículo</th>
                        <th class="text-center">Turno</th>
                        <th class="text-center">Cantidad</th>
                        <th class="text-center">Tarifa</th>
                        <th class="text-center">Ruta</th>
                        <th class="text-center">Producto</th>
                        <th class="text-center" style="width: 120px">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="detalle in $parent.solicitud_vehiculos
                          .tif_det_solicitudes_vehiculos"
                        :key="detalle.id"
                      >
                        <td
                          v-if="
                            detalle.tif_operacion_id == tif_operacion_id['id']
                          "
                        >
                          {{ detalle.tif_tipos_vehiculos.nombre }}
                        </td>
                        <td
                          v-if="
                            detalle.tif_operacion_id == tif_operacion_id['id']
                          "
                        >
                          {{ detalle.turno.nombre }}
                        </td>
                        <td
                          class="text-center"
                          v-if="
                            detalle.tif_operacion_id == tif_operacion_id['id']
                          "
                        >
                          {{ detalle.cantidad_vehiculo }}
                        </td>
                        <td
                          class="text-center"
                          v-if="
                            detalle.tif_operacion_id == tif_operacion_id['id']
                          "
                        >
                          {{ detalle.Tipotarifa }}
                        </td>
                        <td
                          v-if="
                            detalle.tif_operacion_id == tif_operacion_id['id']
                          "
                        >
                          {{ detalle.tif_ruta.nombre }}
                        </td>
                        <td
                          v-if="
                            detalle.tif_operacion_id == tif_operacion_id['id']
                          "
                        >
                          {{ detalle.producto.nombre }}
                        </td>
                        <td
                          class="text-center"
                          v-if="
                            detalle.tif_operacion_id == tif_operacion_id['id']
                          "
                        >
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              data-toggle="modal"
                              data-target="#modal_detalle"
                              @click="getDatosModal(detalle)"
                              v-if="
                                $store.getters.can(
                                  'tif.solicitudesVehiculos.edit'
                                ) && $parent.permite_editar
                              "
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              @click="destroy(detalle)"
                              v-if="
                                $store.getters.can(
                                  'tif.solicitudesVehiculos.edit'
                                ) && $parent.permite_editar
                              "
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal_detalle"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle Solicitud Vehiculo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Tipo Vehículo</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_vehiculo"
                  :class="
                    $v.form.tipo_vehiculo.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="formRepetido()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_vehiculo in $parent.listasForms.tipo_vehiculo"
                    :key="tipo_vehiculo.id"
                    :value="tipo_vehiculo.id"
                  >
                    {{ tipo_vehiculo.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Turno</label>
                <v-select
                  :class="[
                    $v.form.turno.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.turno"
                  placeholder="Turno"
                  label="nombre"
                  :options="$parent.listasForms.turnos"
                  class="form-control form-control-sm p-0"
                  @input="valTurno()"
                ></v-select>
              </div>
              <div class="form-group col-md-6">
                <label for="producto">Producto</label>
                <v-select
                  :class="[
                    $v.form.producto.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.producto"
                  placeholder="Producto"
                  label="nombre"
                  :options="$parent.listasForms.productos"
                  class="form-control form-control-sm p-0"
                  @input="formRepetido()"
                ></v-select>
              </div>

              <div class="form-group col-md-6">
                <label>Tipo Tarifa</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_tarifa"
                  :class="
                    $v.form.tipo_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="formRepetido()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tarifa in $parent.listasForms.tipo_tarifas"
                    :key="tarifa.numeracion"
                    :value="tarifa.numeracion"
                  >
                    {{ tarifa.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Ruta</label>
                <v-select
                  :class="[
                    $v.form.ruta.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.ruta"
                  placeholder="Ruta"
                  label="nombre"
                  :options="$parent.listasForms.rutas"
                  class="form-control form-control-sm p-0"
                  @input="formRepetido()"
                ></v-select>
              </div>
              <div class="form-group col-md-4">
                <label>Cantidad de vehículos</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  v-model="form.cantidad_vehiculo"
                  :class="
                    $v.form.cantidad_vehiculo.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('tif.solicitudesVehiculos.edit') &&
                !$v.form.$invalid
              "
            >
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "SolicitudesVehiculosDetalles",
  components: {
    vSelect,
  },
  data() {
    return {
      moment: moment,
      solicitud_id: null,
      cargando: false,
      form: {
        id: null,
        tif_solicitud_vehiculo_id: this.$parent.solicitud_vehiculos.id,
        tipo_vehiculo: null,
        producto: null,
        cantidad_vehiculo: null,
        turno: null,
        ruta: null,
        tipo_tarifa: null,
      },
      listasForms: {
        operaciones: [],
      },
    };
  },
  validations() {
    return {
      form: {
        tipo_vehiculo: {
          required,
        },
        cantidad_vehiculo: {
          required,
        },
        turno: {
          required,
        },
        producto: {
          required,
        },
        tipo_tarifa: {
          required,
        },
        ruta: {
          required,
        },
      },
    };
  },

  methods: {
    getSolicitud() {
      this.solicitud_id = this.form.tif_solicitud_vehiculo_id;
      axios
        .get("/api/tif/solicitudesVehiculos/show/" + this.solicitud_id)
        .then((response) => {
          this.$parent.solicitud_vehiculos = response.data[0];
        });
    },

    getOperacion() {
      axios
        .get("/api/tif/operaciones/lista", {
          params: {
            bloque_id: this.$route.params.solicitud_vehiculo.bloque_id,
          },
        })
        .then((response) => {
          this.listasForms.operaciones = response.data;
        });
    },

    getDatosModal(detalle = null) {
      if (detalle) {
        this.form = {
          id: detalle.id,
          tif_solicitud_vehiculo_id: this.$parent.solicitud_vehiculos.id,
          bloque_id: this.$parent.solicitud_vehiculos.bloque_id,
          empresa_id: this.$parent.solicitud_vehiculos.empresa_id,
          fecha_fin: this.$parent.solicitud_vehiculos.fecha_fin,
          tipo_vehiculo: detalle.tif_tipos_vehiculos.id,
          cantidad_vehiculo: detalle.cantidad_vehiculo,
          turno: detalle.turno,
          producto: detalle.producto,
          tipo_tarifa: detalle.tipo_tarifa,
          tif_operacion_id: detalle.tif_operacion_id,
          ruta: detalle.tif_ruta,
        };
      }
    },

    getDatosModalAdd(tif_operacion_id) {
      this.resetForm();
      if (tif_operacion_id) {
        this.form = {
          tif_solicitud_vehiculo_id: this.$parent.solicitud_vehiculos.id,
          tif_operacion_id: tif_operacion_id,
        };
        axios({
          method: "GET",
          url: "/api/tif/solicitudesVehiculos/operacionProducto",
          params: tif_operacion_id,
        }).then((response) => {
          this.$parent.listasForms.productos = response.data.productos;
        });
      }
    },

    valTurno() {
      let fecha = moment(this.$parent.solicitud_vehiculos.fecha_ini).format(
        "YYYY-MM-DD"
      );
      let day = moment(fecha).day();
      if (this.form.turno) {
        if (day == 0) {
          day = 7;
        }
        let turn = 0;
        for (let i = 0; i < this.form.turno.dia_turno.length; i++) {
          if (this.form.turno.dia_turno[i].dia_semana == day) {
            turn = 1;
          }
        }
        if (turn == 0) {
          this.form.turno = {};
          this.$swal({
            icon: "error",
            title: `El turno no se encuentra asignado para el dia ${fecha}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    formRepetido() {
      const form = this.form;
      if (
        form.tipo_vehiculo &&
        form.turno &&
        form.tipo_tarifa &&
        form.ruta &&
        form.tif_operacion_id &&
        form.producto
      ) {
        const data =
          this.$parent.solicitud_vehiculos.tif_det_solicitudes_vehiculos;
        data.forEach((detalle) => {
          if (
            form.tipo_vehiculo == detalle.tipo_vehiculo &&
            form.turno.id == detalle.turno_id &&
            form.tipo_tarifa == detalle.tipo_tarifa &&
            form.tif_operacion_id == detalle.tif_operacion_id &&
            form.producto.id == detalle.producto_id &&
            form.ruta.id == detalle.tif_ruta_id
          ) {
            this.resetForm(detalle.tif_operacion_id);
            this.$swal({
              icon: "error",
              title: `Ya existe un detalle solicitud con los datos ingresados.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }
        });
      }
    },

    resetForm(tif_operacion_id) {
      this.form = {
        tif_solicitud_vehiculo_id: this.$parent.solicitud_vehiculos.id,
        bloque_id: this.$parent.solicitud_vehiculos.bloque_id,
        empresa_id: this.$parent.solicitud_vehiculos.empresa_id,
        fecha_fin: this.$parent.solicitud_vehiculos.fecha_fin,
        tipo_vehiculo: null,
        tif_operacion_id: tif_operacion_id,
        cantidad_vehiculo: null,
        turno: null,
        ruta: null,
        producto: null,
        tipo_tarifa: null,
      };
    },
    // Acciones de formularios
    save() {
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.turno_id = this.form.turno.id;
        this.form.producto_id = this.form.producto.id;
        this.form.tif_ruta_id = this.form.ruta.id;
        this.form.bloque_id = this.$parent.solicitud_vehiculos.bloque_id;
        this.form.empresa_id = this.$parent.solicitud_vehiculos.empresa_id;
        this.form.fecha_fin = this.$parent.solicitud_vehiculos.fecha_fin;
        axios({
          method: "POST",
          url: "/api/tif/solicitudesVehiculos/detalle",
          data: this.form,
        })
          .then((response) => {
            this.validar = response.data.estado;
            this.$parent.cargando = false;
            if (this.validar == 2) {
              this.$swal({
                icon: "error",
                title: `Los campos digitados no coinciden con el detalle de los contratos, por favor validar...`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.form.cantidad_vehiculo = null;
              this.form.producto = {};
              this.form.producto_id = null;
              this.form.ruta = {};
              this.form.tif_ruta_id = null;
              this.form.tipo_vehiculo = null;
              this.form.turno_id = null;
              this.form.turno = {};
              this.form.tipo_tarifa = null;
            } else {
              this.$refs.closeModal.click();
              this.getSolicitud();
              this.$swal({
                icon: "success",
                title:
                  "Se actualizó el detalle de la solicitud correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(detalle) {
      this.$swal({
        title: "Esta seguro de eliminar el detalle de la solicitud?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .delete("/api/tif/solicitudesVehiculos/detalle/" + detalle.id)
            .then((response) => {
              this.$parent.cargando = false;
              this.getSolicitud();
              this.validarNomi(detalle.tif_operacion_id);
              this.$swal({
                icon: "success",
                title: "Se eliminó el detalle de la solicitud exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    validarNomi(tif_operacion_id) {
      for (
        let i = 0;
        i <
        this.$parent.solicitud_vehiculos.tif_det_solicitudes_vehiculos.length;
        i++
      ) {
        if (
          this.$parent.solicitud_vehiculos.tif_det_solicitudes_vehiculos[i]
            .tif_operacion_id == tif_operacion_id
        ) {
          return 1;
        }
      }
    },
  },
};
</script>
